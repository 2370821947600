import React from 'react'
import { Box } from 'components/primitives'
import IconField from './IconField'

// Groups Icon, Label, and Input components via IconField and handles id and
// htmlFor props automatically. Put Label before anything. You might want to
// pass autoComplete='off' if native autofill is causing troubles.
// * Sets input (one with isField = true) accordingly when its label has inside
//   prop. If input is small, inside prop would not work. Input also receives
//   smaller padding based on label's autoHide prop. For autoHide prop to work
//   in Formik, you must define value prop. [1]
// * Styles label based on its inside prop value. [2]

const FormField = props => {
  let iconBefore = false
  let styledLabel

  const children = React.Children.toArray(props.children)
  const [field] = children.filter(child => child.type.isField)
  const [label] = children.filter(child => child.type.isLabel)
  const valueNoLabel = !label && field && !!field.props.value
  const showLabel =
    ((label && !label.props.autoHide) || (field && !!field.props.value)) &&
    !valueNoLabel
  const id = field && (field.props.id || field.props.name)
  const large = field && field.props.variant === 'large'

  // [1]
  const styled = children.map((child, i, arr) => {
    if (child.type.isField && arr[i - 1] && arr[i - 1].type.isIcon) {
      iconBefore = true
    }

    if (child === field && large && label.props.inside) {
      return React.cloneElement(child, {
        id,
        style: {
          ...child.props.style,
          transitionProperty: 'padding-top, padding-bottom',
          transitionDuration: '.1s',
          paddingTop: showLabel ? 20.5 : 14.5,
          paddingBottom: showLabel ? 8.5 : 14.5
        }
      })
    }

    return child
  })

  // [2]
  if (label && label.props.inside) {
    styledLabel = React.cloneElement(label, {
      htmlFor: label.props.htmlFor || id,
      fontSize: 10,
      ml: iconBefore ? '40px' : '12px',
      mb: '-20px',
      pt: '6px',
      style: {
        ...label.props.style,
        height: 20,
        transitionProperty: 'opacity',
        transitionDuration: '.1s',
        opacity: showLabel ? 1 : 0
      }
    })
  }

  // [2]
  if (label && !label.props.inside) {
    styledLabel = React.cloneElement(label, {
      htmlFor: label.props.htmlFor || id,
      fontSize: 10,
      mb: '1px',
      style: {
        ...label.props.style,
        transitionProperty: 'opacity',
        transitionDuration: '.1s',
        opacity: showLabel ? 1 : 0
      }
    })
  }

  return (
    <Box {...props}>
      {styledLabel}
      <IconField>{styled}</IconField>
    </Box>
  )
}

const childrenPropType = (props, propName, componentName) => {
  const children = React.Children.toArray(props.children)
  const [label] = children.filter(child => child.type.isLabel)
  const [field] = children.filter(child => child.type.isField)
  const large = field && field.props.variant === 'large'

  if (!field) {
    return new Error(
      `No form field found for ${componentName}. Please include an Input, Select, or other form field as a child.`
    )
  }

  if (!label) {
    return new Error(
      `No label found for ${componentName}. Please include a Label as a child.`
    )
  }

  if (label && label.props.inside && !large) {
    return new Error(
      `Labe can't be inside for small ${componentName}. Please remove 'inside' prop.`
    )
  }
}

FormField.propTypes = {
  children: childrenPropType
}

FormField.displayName = 'FormField'

export default FormField
