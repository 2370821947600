import React from 'react'
import Head from 'components/modules/Head'
import { Box } from 'components/primitives'
import TileCalculator from 'components/tile-calculator'
import Text from 'components/core/Text'

export default props => {
  return (
    <Box p={4}>
      <Head title='타일 계산기' />
      <TileCalculator />
      <Text fontSize={0} color='lightText' mt={3}>
        *가격은 소수점 0자리까지, 여유분은 소수점 2자리까지 반올림하고 박스는
        올림 처리했습니다.
      </Text>
      <Text fontSize={0} color='lightText' mt={1}>
        **판매하는 곳에 따라 비용을 산정하는 방식이 다를 수 있습니다.
      </Text>
    </Box>
  )
}
