import styled from '@emotion/styled'
import { Card, Box } from 'components/primitives'
import Input from './Input'

// Extends Card.
// Wraps multiple Input or Box extended components with only a single border
// style around child elements. This can be used for date range inputs that look
// like a single input. Note that focus and validation states will need to pass
// a value to the borderColor prop. Also note that this component is meant to
// evenly divide widths of child elements, which is what > ${Box} is for.

const InputGroup = styled(Card)`
  display: flex;
  align-items: center;

  > ${Box} {
    width: 100%;
    flex: 1 1 auto;
  }

  ${Input} {
    border: 0;
    box-shadow: none !important;
  }
`

InputGroup.defaultProps = {
  borderWidth: 1,
  borderRadius: 1,
  borderStyle: 'solid',
  borderColor: 'gray'
}

export default InputGroup
