import React from 'react'
import { Box, Flex, Card } from 'components/primitives'
import Text from 'components/core/Text'
import Heading from 'components/core/Heading'
import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import { Row, Column } from 'components/core/Layout'
import Tooltip, { ButtonTooltip } from 'components/modules/Tooltip'
import Info from 'components/icons/feather-info.svg'
import { Label as RadioLabel, Radio } from '@rebass/forms'
import css from '@styled-system/css'
import toNumber from 'lodash/toNumber'
import ceil from 'lodash/ceil'
import round from 'lodash/round'
import InputGroup from 'components/core/InputGroup'
import Input from 'components/core/Input'
import InputLabel from 'components/core/Label'
import FormField from 'components/core/FormField'
import Divider from 'components/core/Divider'
import Button from 'components/core/Button'
import Clipboard from 'clipboard'
import Link from './core/Link'

const sliderText = value => `${value}m²`
const marks = [
  {
    value: 20,
    label: '20m²'
  },
  {
    value: 40,
    label: '40m²'
  }
]

const StyledSlider = withStyles({
  root: {
    color: '#181818'
  },
  thumb: {
    '&:focus,&:hover,&$active': {
      boxShadow: '0px 0px 0px 8px rgba(24, 24, 24, 0.16)'
    }
  }
})(Slider)

const Label = props => <Text fontSize={1} color='lightText' {...props} />

const RadioButton = props => (
  <Radio
    css={css({
      'input:checked ~ &': {
        color: 'text'
      }
    })}
    {...props}
  />
)

const ResultColumn = props => (
  <Column
    display='flex'
    flexDirection='column'
    alignItems='center'
    mb={4}
    width={[1, 1 / 2]}
    {...props}
  />
)

export default props => {
  const [coverageNeeded, setCoverageNeeded] = React.useState(20) // localStorage
  const [extra, setExtra] = React.useState(1.1) // localStorage
  const [productInfo, setProductInfo] = React.useState('perBox') // localStorage
  const [pricePerBoxInput, setPricePerBoxInput] = React.useState(0)
  const [pricePerCoverageInput, setPricePerCoverageInput] = React.useState(0)
  const [coveragePerBox, setCoveragePerBox] = React.useState(0)

  const pricePerBox =
    productInfo === 'perBox'
      ? pricePerBoxInput
      : round(pricePerCoverageInput * coveragePerBox, 0)
  const pricePerCoverage = round(pricePerBox / coveragePerBox, 0)
  const boxesNeeded = ceil((coverageNeeded * extra) / coveragePerBox)
  const totalPrice = boxesNeeded * pricePerBox
  const preciseExtra = round(
    ((boxesNeeded * coveragePerBox - coverageNeeded) * 100) / coverageNeeded,
    2
  )

  const pricePerBoxLabel =
    isNaN(pricePerBox) || !isFinite(pricePerBox) || pricePerBox === 0
      ? `–`
      : `${pricePerBox.toLocaleString()}원 / box`
  const pricePerCoverageLabel =
    isNaN(pricePerCoverage) ||
    !isFinite(pricePerCoverage) ||
    pricePerCoverage === 0
      ? `–`
      : `${pricePerCoverage.toLocaleString()}원 / m²`
  const boxesNeededLabel = isFinite(boxesNeeded) ? `${boxesNeeded}박스` : `–`
  const totalPriceLabel =
    isNaN(totalPrice) || !isFinite(totalPrice) || totalPrice === 0
      ? `–`
      : `${totalPrice.toLocaleString()}원`
  const preciseExtraLabel = isNaN(preciseExtra) ? `–` : `${preciseExtra}%`

  React.useEffect(() => {
    new Clipboard('.clipboard')
  }, [])

  return (
    <Card
      maxWidth={640}
      p={[3, 4]}
      border='1px solid'
      borderColor='muted'
      borderRadius={16}
    >
      <Heading mb={4}>타일 계산기</Heading>
      <Box mb={4}>
        <Flex mb={1} alignItems='center'>
          <Label id='coverage-slider'>시공하려는 공간의 면적</Label>
          <Tooltip title='타일이 쓰이는 전체 면적을 설정합니다. 예를 들어 한쪽 벽면을 시공한다면 벽의 길이와 높이를 곱한 값입니다.'>
            <Info size={12} ml={1} />
          </Tooltip>
        </Flex>
        <Row mx={-1}>
          <Column px={1}>
            <StyledSlider
              defaultValue={20}
              getAriaValueText={sliderText}
              aria-labelledby='coverage-slider'
              valueLabelDisplay='auto'
              step={0.5}
              min={1}
              max={60}
              marks={marks}
              onChange={(e, value) => setCoverageNeeded(value)}
            />
          </Column>
          <Column order={[-1, 0]} px={1} width={[1, 1 / 6]} display='flex'>
            <Text ml={[null, 'auto']} fontSize={3} fontWeight='bold'>
              {coverageNeeded}m²
            </Text>
          </Column>
        </Row>
      </Box>
      <Box mb={4}>
        <Flex mb={2} alignItems='center'>
          <Label>필요한 여유분</Label>
          <Tooltip title='시공면적보다 얼마나 더 여유 있게 준비할지를 설정합니다. 타일이 커질수록 버리는 부분이 많아지므로 400각 이상의 타일은 20%의 여유분을 준비하면 좋아요.'>
            <Info size={12} ml={1} />
          </Tooltip>
        </Flex>
        <Row mx={-1}>
          <Column width={[1 / 2, 1 / 3, 1 / 6]} px={1}>
            <RadioLabel>
              <RadioButton
                name='extra'
                id='extra-0'
                value={1}
                checked={extra === 1}
                onChange={e => setExtra(toNumber(e.target.value))}
              />
              0%
            </RadioLabel>
          </Column>
          <Column width={[1 / 2, 1 / 3, 1 / 6]} px={1}>
            <RadioLabel>
              <RadioButton
                name='extra'
                id='extra-5'
                value={1.05}
                checked={extra === 1.05}
                onChange={e => setExtra(toNumber(e.target.value))}
              />
              5%
            </RadioLabel>
          </Column>
          <Column width={[1 / 2, 1 / 3, 1 / 6]} px={1}>
            <RadioLabel>
              <RadioButton
                name='extra'
                id='extra-10'
                value={1.1}
                checked={extra === 1.1}
                onChange={e => setExtra(toNumber(e.target.value))}
              />
              10%
            </RadioLabel>
          </Column>
          <Column width={[1 / 2, 1 / 3, 1 / 6]} px={1}>
            <RadioLabel>
              <RadioButton
                name='extra'
                id='extra-15'
                value={1.15}
                checked={extra === 1.15}
                onChange={e => setExtra(toNumber(e.target.value))}
              />
              15%
            </RadioLabel>
          </Column>
          <Column width={[1 / 2, 1 / 3, 1 / 6]} px={1}>
            <RadioLabel>
              <RadioButton
                name='extra'
                id='extra-20'
                value={1.2}
                checked={extra === 1.2}
                onChange={e => setExtra(toNumber(e.target.value))}
              />
              20%
            </RadioLabel>
          </Column>
        </Row>
      </Box>
      <Box mb={4}>
        <Label mb={2}>타일 정보</Label>
        <InputGroup maxWidth={400}>
          {productInfo === 'perBox' ? (
            <FormField>
              <InputLabel inside>박스당 판매가 (₩)</InputLabel>
              <Input
                variant='large'
                type='number'
                id='product-price-box'
                name='product-price-box'
                value={pricePerBoxInput}
                onChange={e => setPricePerBoxInput(e.target.value)}
              />
            </FormField>
          ) : (
            <FormField>
              <InputLabel inside>제곱미터당 판매가 (₩)</InputLabel>
              <Input
                variant='large'
                type='number'
                id='product-price-coverage'
                name='product-price-coverage'
                value={pricePerCoverageInput}
                onChange={e => setPricePerCoverageInput(e.target.value)}
              />
            </FormField>
          )}
          <FormField>
            <InputLabel inside>박스당 시공면적 (m²)</InputLabel>
            <Input
              variant='large'
              type='number'
              id='product-coverage'
              name='product-coverage'
              value={coveragePerBox}
              onChange={e => setCoveragePerBox(e.target.value)}
            />
          </FormField>
        </InputGroup>
        <Link
          color='lightText'
          variant='underline'
          css={css({
            ':hover': {
              color: 'text'
            }
          })}
          fontSize={0}
          as='button'
          onClick={() =>
            setProductInfo(productInfo === 'perBox' ? 'perCoverage' : 'perBox')
          }
        >
          {productInfo === 'perBox'
            ? '제곱미터당 판매가를 알고 있나요?'
            : '박스당 판매가를 알고 있나요?'}
        </Link>
      </Box>
      <Divider mb={4} />
      <Row>
        <ResultColumn>
          {productInfo === 'perBox' ? (
            <>
              <Text mb={2} fontSize={4}>
                {pricePerCoverageLabel}
              </Text>
              <Label>제곱미터당 가격</Label>
            </>
          ) : (
            <>
              <Text mb={2} fontSize={4}>
                {pricePerBoxLabel}
              </Text>
              <Label>박스당 가격</Label>
            </>
          )}
        </ResultColumn>
        <ResultColumn>
          <Text mb={2} fontSize={4}>
            {boxesNeededLabel}
          </Text>
          <Label>필요한 박스의 수</Label>
        </ResultColumn>
        <ResultColumn>
          <Text mb={2} fontSize={4}>
            {totalPriceLabel}
          </Text>
          <Label>총 비용</Label>
        </ResultColumn>
        <ResultColumn>
          <Text mb={2} fontSize={4}>
            {preciseExtraLabel}
          </Text>
          <Label>실 여유분</Label>
        </ResultColumn>
      </Row>
      <Flex flexDirection='column' alignItems='center'>
        <ButtonTooltip
          buttonProps={{
            className: 'clipboard',
            'data-clipboard-text':
              productInfo === 'perBox'
                ? `
제곱미터당 가격: ${pricePerCoverageLabel}
필요한 박스의 수: ${boxesNeededLabel}
총 비용: ${totalPriceLabel}
실 여유분: ${preciseExtraLabel}
`
                : `
박스당 가격: ${pricePerBoxLabel}
필요한 박스의 수: ${boxesNeededLabel}
총 비용: ${totalPriceLabel}
실 여유분: ${preciseExtraLabel}
`
          }}
          title='복사되었습니다!'
        >
          복사하기
        </ButtonTooltip>
      </Flex>
    </Card>
  )
}
